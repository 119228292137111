import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import debounce from "debounce"
import { useMemo } from "react"
import { HiOutlineSearch } from "react-icons/hi"
import { sanitizeDomain } from "~/utils/sanitizeDomain"

type Props = {
  initial?: string | null
  onChange: (searchTerm: string) => void
  placeholder?: string
}

export function SearchInput({
  initial,
  onChange,
  placeholder = "Search Companies or Domains",
}: Props) {
  const debouncedOnChange = useMemo(() => debounce(onChange, 250), [onChange])

  return (
    <>
      <InputGroup flex="none" width="auto">
        <InputLeftElement color="gray.400">
          <HiOutlineSearch />
        </InputLeftElement>
        <Input
          variant="filled"
          rounded="full"
          type="search"
          placeholder={placeholder}
          defaultValue={initial ?? ""}
          w="300px"
          transitionProperty="width"
          transitionTimingFunction="ease-in-out"
          transitionDuration="0.3s"
          _focus={{
            w: "340px",
          }}
          _hover={{
            bgColor: "gray.100",
          }}
          textAlign="center"
          onChange={(event) => {
            if (event.target.value.includes("http")) {
              debouncedOnChange(sanitizeDomain(event.target.value))
              return
            }
            debouncedOnChange(event.target.value)
          }}
        />
      </InputGroup>
    </>
  )
}
