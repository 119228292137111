import { format, getHours } from "date-fns"
import { isDateValid } from "~/utils/isDateValid"
import { parse } from "date-fns"

export const SECONDS_TEN_MINUTES = 60 * 10
export const getPeriodOfDate = (date: Date) => {
  const hours = getHours(date)

  if (hours >= 0 && hours < 12) {
    return "morning"
  } else if (hours >= 12 && hours < 18) {
    return "afternoon"
  }

  return "evening"
}

export const currentYear = new Date().getFullYear()

export const beginningOfTime = new Date(0)

export function isoDate(d: Date) {
  return d.toISOString().slice(0, 10)
}

export function guardedIsoDate(d: Date | null): string | undefined {
  return isDateValid(d) ? format(d, "yyyy-MM-dd") : undefined
}

/**
 * Implemented as there is an issue with the sub() method from date-fns, when
 * substracting one month from 01/11/2023 it goes to 30/09/2023 due to mishandling
 * Date() objects which are locale aware.
 */
export function subtractMonth(d: Date) {
  if (d.getMonth() === 0) {
    return new Date(Date.parse(`${d.getFullYear() - 1}-12-01T00:00:00Z`))
  }
  return new Date(
    Date.parse(
      `${d.getFullYear()}-${String(d.getMonth()).padStart(2, "0")}-01T00:00:00Z`
    )
  )
}

export function absoluteDifferenceSeconds(a: Date, b: Date) {
  return Math.abs(a.getTime() / 1000 - b.getTime() / 1000)
}

/**
 * Convert an arbitrary date string to a Date, *forcibly* make the date UTC
 * since that makes far more sense.
 */
export function convertDateString(dateString: string): Date {
  const date = new Date(Date.parse(dateString))
  return new Date(date.toLocaleString("UTC") + " UTC")
}

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as unknown as number)
}

/**
 * Assume the date is in "English" format, and if not try and parse it
 * as an ISO string
 * @param date_string
 */
export function parseDate(date_string: string): Date {
  let outcome = parse(date_string, "dd/MM/yyyy", new Date())

  if (!isValidDate(outcome)) {
    outcome = new Date(Date.parse(date_string))
  }

  return outcome
}
